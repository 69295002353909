/**
 * Adiglobaldistribution colors schema
 */

$app-color-primary-dark: #ebb227;
$app-color-primary: #ffcd00;
$app-color-tone: #ffcd00;
$app-color-primary-light-1: #ffcd00;
$app-color-primary-light-3: #eeeeee;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #77706f;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, #fff 0 , #fff 100%);