$btn-focus-box-shadow: none;

$btn-border-radius: 22.5px;
$btn-height: 45px;
$btn-line-height: 45px;
$btn-font-size: 14px;
$btn-font-weight: 700;
$btn-letter-spacing: 0.8px;

$btn-primary-color: #414141;
$btn-primary-background: $app-color-primary;
$btn-primary-box-shadow: none;
$btn-primary-hover-box-shadow: none;

$btn-primary-hover-background: $app-color-primary-dark;
$btn-primary-hover-color: #414141;

$btn-outline-primary-box-shadow: none;

$btn-outline-primary-border: $app-color-primary;
$btn-outline-primary-color: #414141;
$btn-outline-primary-hover-border: #005a91;
$btn-outline-primary-hover-box-shadow: none;
$btn-outline-primary-hover-color: #414141;

[data-skin="adnoc"] {

  .btn-link {
    letter-spacing: normal !important;
    transition: none;

    &:hover,&:focus,&:active {
      text-decoration: none;
    }
  }


}
