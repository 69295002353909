$input-focus-box-shadow: none;

// .form-group .label
$form-group-label-font-weight: 600;
$form-group-label-text-transform: capitalize;
$form-group-label-color: #2D2926;
$form-group-label-line-height: 21px;
$form-group-label-font-size: 14px;
$form-group-label-margins: (
  "bottom": 5px
);

// .form-group.label-large .label
$form-group-label-large-label-font-size: 14px;

// .form-group .form-control
$form-control-border-color: #686e74;
$form-control-border-radius: 0;
$form-control-height: 48px;
$form-control-padding: 12px 24px 12px 16px;
$form-control-font-size: 14px;
$form-control-line-height: 24px;
$form-control-background: #fff;
$form-control-disabled-background: #eeeeee;



// .form-group .form-control::placeholder
$form-control-placeholder-font-style: normal;
$form-control-placeholder-text-transform: none;
