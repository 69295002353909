// .address-form .title
$address-form-title-text-transform: uppercase;
$address-form-title-text-align: center;
$address-form-title-text-font-weight: bold;
$address-form-title-text-paddings: (
  "bottom": $spacer
);

// .address-form .info
$address-form-info-font-size: 70%;
$address-form-info-text-align: center;
$address-form-info-color: $danger;